import { parseDate } from '@arcadiapower/warbler';
import { Notifications } from 'hooks/use-account-notifications.hook';

export const actionTypes = {
  advocacy: 'go_to_advocacy_email_form',
  goToMembership: 'go_to_membership',
  ipaTerms: 'go_to_ipa_terms',
  moveRequest: 'go_to_move_request_form',
  noAction: 'no_action',
  paymentMethod: 'go_to_payment_method',
  utilityCredential: 'go_to_utility_credential',
  vnmTerms: 'go_to_vnm_terms',
  utilityCredentialPopUp: 'go_to_utility_credential_pop_up',
  turnOffAutoPay: 'go_to_utility_site',
  checkLmiEligibility: 'go_to_check_lmi_eligibility',
};

export const priorityTypes = {
  warning: 'warning',
  highError: 'high_priority_task_error',
  highNonError: 'high_priority_task_non_error',
  low: 'low_priority_task',
  update: 'update',
};

const priorityMap = {
  high_priority_task_error: 4,
  high_priority_task_non_error: 3,
  warning: 2,
  low_priority_task: 1,
  update: 0,
};

export const isWarning = (priorityType: string): boolean =>
  priorityType === priorityTypes.warning;
export const isHighError = (priorityType: string): boolean =>
  priorityType === priorityTypes.highError;
export const isHigh = (priorityType: string): boolean =>
  priorityType === priorityTypes.highError ||
  priorityType === priorityTypes.highNonError;
export const isLow = (priorityType: string): boolean =>
  priorityType === priorityTypes.low;
export const isUpdate = (priorityType: string): boolean =>
  priorityType === priorityTypes.update;

export enum NotificationKey {
  ipaDisclosure = 'ipa_disclosure_signature_needed',
  signContract = 'sign_contract',
  signStateContracts = 'sign_state_contracts',
  advocacyCampaignAvailable = 'advocacy_campaign_available',
  advocacyCampaignAvailableForAccount = 'advocacy_campaign_available_for_account',
  finalBill = 'final_bill_detected',
  movingAccountDeleted = 'moving_account_deleted',
  vnmStatusWaitlistLive = 'vnm_status_waitlist_live',
  vnmStatusWaitlistNotLive = 'vnm_status_waitlist_not_live',
  vnmStatusBilled = 'vnm_status_billed',
  vnmStatusPending = 'vnm_status_pending',
  lowIncomeHousehold = 'low_income_household',
}

export const KEYS_FOR_NOTIFICATIONS_WITH_LOVEBIRD_LINKS = [
  NotificationKey.vnmStatusWaitlistLive,
  NotificationKey.vnmStatusWaitlistNotLive,
  NotificationKey.vnmStatusBilled,
];

const sortByPriorityAndDate = (notifications: Notifications): Notifications => {
  return notifications.sort((a, b) => {
    const priorityValueA = priorityMap[a.priorityType];
    const priorityValueB = priorityMap[b.priorityType];
    if (priorityValueA > priorityValueB) {
      return -1;
    } else if (priorityValueA < priorityValueB) {
      return 1;
    } else {
      return (
        parseDate(b.createdAt).getTime() - parseDate(a.createdAt).getTime()
      );
    }
  });
};

export const filterAndSortCurrentNotifications = (
  notifications: Notifications
): Notifications => {
  if (!notifications) return [];
  const filtered = notifications.filter(
    notification =>
      isHigh(notification.priorityType) || notification.read === false
  );
  return sortByPriorityAndDate(filtered);
};

export const filterAndSortPastNotifications = (
  notifications: Notifications
): Notifications => {
  if (!notifications) return [];
  const filtered = notifications.filter(
    notification =>
      !isHigh(notification.priorityType) && notification.read === true
  );
  return filtered.sort(
    (a, b) =>
      parseDate(b.createdAt).getTime() - parseDate(a.createdAt).getTime()
  );
};

export const filterAndSortDashboardNotifications = (
  notifications: Notifications
): Notifications => {
  if (!notifications) return [];
  const filtered = notifications.filter(
    notification => !isUpdate(notification.priorityType)
  );
  return sortByPriorityAndDate(filtered);
};
