export const billing = {
  heading: 'Billing',
  dashboard: {
    billingHistory: {
      title: 'Billing history',
      ariaTitle: 'Details for your statement of %{date}',
      links: {
        viewAll: 'View all statements',
      },
      energyUsage: '%{kwh} kWh',
      badge: {
        refundedPartial: 'Partial refund: $%{dollarAmount}',
        refundedFull: 'Refunded',
        disputed: 'Disputed',
      },
    },
    billStatus: {
      active: {
        freeTrial: {
          nonSponsored: {
            subtitle:
              'No, we didn’t discover cold fusion. But your Arcadia membership is free until %{date}.',
          },
          sponsored: {
            subtitle:
              'No, we didn’t discover cold fusion. But your Arcadia membership is covered thanks to your employer, so it’s free!',
          },
          title: 'Free Energy.',
        },
        latestStatementIssued: {
          title: 'A powerful statement.',
          subtitle:
            'On %{date} a %{price} charge will make sure 100% of your electricity is renewable.',
          alternateSubtitle:
            'On %{date} you will be charged for your latest energy statement.',
        },
        latestStatementPaid: {
          title: 'You clean up nice.',
          subtitle:
            'We received your payment on %{date}. Thanks for supporting clean energy with your latest statement.',
        },
      },
      closed: {
        title: 'Statements',
        subtitle: 'We received your last payment on %{lastPaymentDate}.',
      },
    },
    latestStatement: {
      title: 'Your %{monthName} bill',
      totalDue: {
        title: 'Total due to Arcadia',
        titleForDeveloperPayment: 'Total due',
        badge: {
          applied: '%{percent}% Solar savings applied',
          appliedWithoutPercent: 'Solar savings applied',
        },
      },
      moreStatements: '%{count} more statement(s)...',
      moreAccounts: '%{count} more account(s)...',
      utilityStatementSummary: {
        servicePeriod: '<b>Service Period:</b> %{start} - %{end}',
        productionPeriod: '<b>Production Period:</b> %{start} - %{end}',
        serviceAddress: '<b>Service Address:</b> %{address}',
        utilityAccountNumber: '<b>Utility Account Number:</b> %{number}',
      },
      links: {
        payInvoice: 'Pay invoice',
        fullStatement: 'View detailed bill',
        delayPayment: 'Delay payment',
        updatePayment: 'Update payment method',
      },
      delayPayment: {
        title: 'Delay Payment',
        subtitle: 'Select a new payment date from the available dates below',
        description:
          'Please note that the dates we’ve provided should allow enough time for processing, but any delay could result in a late payment to your utility.',
        notification: 'Payment date delayed.',
        ineligibleNotification: 'Statement is ineligible for delay payment',
        buttons: {
          submit: 'Save new payment date',
          cancel: 'Nevermind',
        },
      },
    },
  },
  statement: {
    currentLocation: 'Statement',
    header: 'Your %{month} bill',
    title: 'Statement for %{email}',
    downloadButton: 'Download Arcadia Bill',
    viewOriginal: 'View original %{utilityName} statement',
    labels: {
      lineItems: {
        product: 'Product',
        amount: 'Amount',
      },
    },
    csvDownload: {
      summary:
        'Because you have a large statement, we have provided downloadable CSV bill breakdown.',
      button: 'Download file',
    },
    paymentInstructions: {
      nonAutoPayCheckToArcadia: {
        instruction:
          'We request that you submit payment by following the "pay invoice" link OR payment by check to Arcadia.',
      },
      nonAutoPayCheckToDeveloper: {
        instruction:
          'We request that you submit your payment by check to your solar project at the following address:',
      },
      nonAutoPayAchToDeveloper: {
        instruction:
          'We request that you submit your payment by ACH to your solar project:',
        account: 'Account number:',
        routing: 'Routing number:',
        bank: 'Bank:',
      },
    },
    utilityStatementSummary: {
      servicePeriod: '<b>Service Period:</b> %{start} - %{end}',
      productionPeriod: '<b>Production Period:</b> %{start} - %{end}',
      serviceAddress: '<b>Service Address:</b> %{address}',
      utilityAccountNumber: '<b>Utility Account Number:</b> %{number}',
    },
    error: {
      title: 'Statement',
    },
    communitySolarUpdate: {
      title: 'Community Solar Status',
      subtitle:
        "Here’s an update about where you are in the enrollment process. Once you are matched to a solar farm, and the solar farm produces energy, you'll start to see savings on your bill. You can see more in your dashboard.",
    },
    links: {
      payInvoice: 'Pay invoice',
      fullStatement: 'View detailed invoice',
      delayPayment: 'Delay payment',
      updatePayment: 'Update payment method',
    },
    totalDue: {
      title: 'Total due to Arcadia',
      titleForDeveloperPayment: 'Total due',
      scheduledAt:
        'Your %{paymentMethodCopy} will be automatically charged on %{date}',
      chargedAt: 'Your %{paymentMethodCopy} was charged on %{date}',
      badge: {
        applied: '%{percent}% Solar savings applied',
        appliedWithoutPercent: 'Solar savings applied',
      },
    },
    billBreakdown: {
      title: 'Bill breakdown',
      energyUsed: 'Total energy usage: %{kwh} kWh',
    },
    delayedCredit: {
      header: "Why didn't I receive Community Solar Credits this month?",
      content:
        "We're sorry we couldn't apply your solar credits and savings this month. This may be an issue on either our end or your utilities end. Either way, we are working diligently to fix this, and you should see these credits applied on a future bill. Reach out to our team if you have questions.",
    },
    dualBillingOverview: {
      title: 'Payment overview',
      explanation:
        "You're on a <b>Dual Billing</b> product which means you'll get a bill both from Arcadia and from your utility.",
      info: 'We rely on your utility and your solar farm to help us determine how much energy you used in a given service period. As a result, you may see a delayed bill from Arcadia for a past service period.',
      readMore: 'Read more here',
      toUtility: 'To %{utilityDisplayName}',
      autoCharged: 'Automatically charged on %{formattedPaymentDate}',
      dueOn: 'Due on %{formattedPaymentDate}',
      actionNeeded: 'Action needed',
      noActionNeeded: 'No action needed',
      payByCheck: 'Pay by check',
      possibleActionNeeded: 'Possible action needed',
      payRemainder:
        "You'll pay the remainder of your total energy cost to %{utilityDisplayName}, which already has the Arcadia (%{formattedArcadiaPortion}) portion deducted.",
    },
    lineItems: {
      bundledUtilityCharge: {
        subtitles: {
          utilityCharge:
            '<span class="notranslate">%{utilityName}</span> Bill: %{charge}',
          solarFarmCharge: 'Community Solar Farm: %{charge}',
        },
        tooltip: {
          copy: 'Your total utility bill will be lower with your community solar savings. Part of your existing utility bill is redirected to support your solar farm. ',
          label: 'Learn more about this utility charge',
        },
      },
      unbundledVNMSolarCharge: {
        servicePeriod: 'Service period: %{startDate} - %{endDate}',
        percentSavings:
          '%{percentSavings}% savings from community solar farm: %{savings}',
        childLineItem: '%{name}: %{amount}',
      },
      calculatedSolarSavings: {
        subtitles: {
          savings:
            '%{percentSavings}% savings from Community Solar Farm: %{savings}',
        },
        tooltip: {
          copy: 'You get %{percentSavings}% of the Community Solar Farm amount as savings off your utility bill, and the remaining %{remainder}% goes back to the farm to keep it running.',
          label: 'Learn more about your solar savings',
        },
      },
      total: 'Total',
      vnmSolarCharge: {
        title: 'Solar Farm Production',
        tooltip: {
          copy: 'This is the portion of your total %{utilityName} bill, that your solar farm generated on your behalf, this month. You may see %{amount} as a credit applied on your original %{utilityName} bill.',
          label: 'Learn more about your solar farm production',
        },
        savings: {
          title: 'Savings',
          tooltip: {
            copy: 'Your solar farm membership earns you a %{savingsPercentage}% discount, each month.',
            label: 'Learn more about your savings',
          },
        },
        subtotal: {
          title: 'Discounted Subtotal',
          tooltip: {
            copy: 'This is the discounted amount that you pay for the solar portion of your %{utilityName} bill.',
            label: 'Learn more about your discounted subtotal',
          },
        },
      },
      vnmSolarChargePreCredit: {
        title: 'Solar Farm Production',
        subtitle:
          'Once your solar farm goes live, this will show the portion of your total %{utilityName} bill that your solar farm generated on your behalf.',
        savings: {
          title: 'Savings',
          subtitle:
            'Soon, you’ll see savings from your community solar membership, each month.',
        },
        subtotal: {
          title: 'Discounted Subtotal',
          subtitle:
            'Soon, you’ll see the discounted amount that you pay for the solar portion of your %{utilityName} bill.',
        },
        badges: {
          comingSoon: 'Coming Soon!',
        },
      },
      vnmSolarChargeDelayedCredit: {
        title: 'Solar Farm Production',
        subtitle:
          'You did not receive any community solar credits this month. You should see these credits applied on a future bill.',
        badges: {
          delayed: 'Delayed',
        },
      },
      localUtilityChargeWithVnmSolar: {
        title: 'Remainder of your bill',
        tooltip: {
          copy: 'This is the remainder of your total %{utilityName} bill, which Arcadia pays to %{utilityName} on your behalf.',
          label: 'Learn more about the remainder of your bill',
        },
      },
      localUtilityChargeWithoutVnmSolar: {
        title: 'Your full utility bill',
        tooltip: {
          copy: 'This is exactly the same as your %{utilityName} bill, which Arcadia pays to %{utilityName} on your behalf.',
          label: 'Learn more about this utility charge',
        },
      },
      missedOverChargeCorrection: {
        title: 'Adjustments',
        subtitle:
          'You are receiving a credit on this bill for a billing mistake during %{periodName} %{periodStartDate} - %{periodEndDate}',
        tooltip: {
          label: 'Learn more about this charge',
        },
        servicePeriod: 'service period',
        productionPeriod: 'production period',
      },
      missedUnderChargeCorrection: {
        title: 'Adjustments',
        subtitle:
          'You are receiving a debit on this bill for a billing mistake during %{periodName} %{periodStartDate} - %{periodEndDate}',
        tooltip: {
          label: 'Learn more about this charge',
        },
        servicePeriod: 'service period',
        productionPeriod: 'production period',
      },
      overChargeCorrection: {
        title: 'Adjustments',
        subtitle:
          'This credit covers the amount we overcharged you on %{date}.',
        tooltip: {
          label: 'Learn more about this credit',
        },
      },
      underChargeCorrection: {
        title: 'Adjustments',
        subtitle:
          'This additional charge covers the amount we missed on %{date}.',
        tooltip: {
          label: 'Learn more about this charge',
        },
      },
      extras: {
        title: 'Arcadia Extras',
        tooltip: {
          copy: 'These are the rest of your non-community solar charges and credits.',
          label: 'Learn more about these charges',
        },
      },
    },
    pieCharts: {
      yourBillWithArcadia: {
        header: 'Your bill, with Arcadia',
        content: 'Swipe right to see how your bill breaks down!',
        legend: {
          utility: '%{utility}: %{total}',
          solar: 'Solar farm: %{total}',
          savings: '<b>Solar savings: %{total}</b>',
        },
      },
      yourBillWithoutArcadia: {
        header: 'Your bill, without Arcadia',
        content:
          'Without Arcadia, you would have purchased your entire energy bill through your utility, at a non-discounted rate, with zero solar savings.',
        callout: 'Your total energy bill without Arcadia is more expensive.',
      },
      totalDueToArcadia: {
        header: 'Total due to Arcadia',
        content:
          'With Arcadia, your total energy bill has solar savings applied, making your overall bill cheaper.',
        callout: 'Your total energy bill with Arcadia is cheaper.',
      },
      solarFarmProduction: {
        header: 'Solar farm production',
        content:
          'This is the portion of your total %{utility} bill, that your solar farm generated on your behalf, this month.',
        callout:
          'of your total %{utility} bill is paid for using solar credits from your solar farm.',
      },
      savings: {
        header: 'Savings',
        content:
          'Your solar farm membership earns you <b>a %{rate} discount</b> on your solar credits, each month.',
        callout: 'in savings!',
      },
      communitySolarSubtotal: {
        header: 'Community Solar subtotal',
        content: 'is what you pay for these solar credits.',
        callout:
          'This is the discounted amount that you paid for the solar portion of your bill. That’s %{rate} savings!',
      },
      remainderOfYourBill: {
        header: 'Remainder of your bill',
        content:
          'We try to maximize your savings each month, but a portion of your total utility bill may not be covered entirely by your solar farm production.',
        callout: 'of your total energy bill is not covered by your solar farm.',
      },
    },
    faq: {
      header: 'Frequently asked questions',
      everyone: [
        {
          question: 'Why is my bill higher this month?',
          answer: [
            'Your Utility Bill is variable, and can change each month. Many factors affect your Utility Bill, such as:',
            "<ul><li><b>Increased Electric and Gas Usage:</b> Changes in weather, guests, new appliances etc., can all lead to higher usage and a higher bill.</li><li><b>Price per kWh:</b> If you choose to participate in the deregulated market, your supply rate may be higher than your utility's standard offer service (SOS) rate resulting in an unusually high bill.</li><li><b>Time of year:</b> During seasons with more energy demand, utilities and suppliers often raise rates due to the spike in demand for energy. This happens particularly during winter and summer months.</li><li><b>Time of day:</b> Some utilities have a time of use plan. If you use energy at peak hours, the rate per kWh may be higher than at another time of day.</li></ul>",
          ],
        },
        {
          question: 'I’m about to move, what do I do?',
          answer: [
            'Arcadia is available across the United States and you can transfer your account from home to home with few exceptions.',
            'First, be sure to contact your utility to schedule a stop service request and start service request. If enrolled in Auto-Pay with Arcadia, we will pick up and pay your final utility bill as usual.',
            "After you've contacted your utility, you can start your move request, by navigating to the “Moving” page, <a href='/settings/moving'>here</a>.",
          ],
        },
        {
          question: 'How is my bill date determined?',
          answer: [
            'We determine your bill date by the day that your utility issues your monthly bill. Our system is automated to send out your Arcadia bill as soon as we receive the monthly charges from your utility. Your Arcadia monthly automatic payment will always occur 3 days after we issue your statement.',
            'This allows us enough time to receive your payment, process it, and send it to your utility company before the due date. We guarantee that any payment made through our platform will post to your utility account on or before the due date.',
          ],
        },
      ],
      cs: [
        {
          question: 'Why are my savings different this month?',
          answer: [
            'Solar production and subsequent credits are based on several factors, which can result in different savings each month. The main factor is seasonality — production from your solar farm tends to be lower in winter, and higher in the summer. Therefore, your savings will fluctuate throughout the year.',
          ],
        },
      ],
      csPreCredit: [
        {
          question: 'Where are my savings this month?',
          answer: [
            'We are working to connect you to a local Community solar farm. Once we connect you and that farm starts producing energy, you will start seeing savings on your bill every month for as long as you are with us, for the determined term length of the project.',
          ],
        },
      ],
      csPostCredit: [
        {
          question: 'Where is my Community Solar credit this month?',
          answer: [
            'You occasionally may see a month where Solar Credits are not applied to your bill. This can happen for a variety of reasons, which Arcadia works diligently to resolve, without any action required from you. These credits are most often applied to your utility bill the following month, so your savings are accounted for.',
          ],
        },
      ],
      csAndAcb: [
        {
          question:
            'Why do I need to pay Arcadia if I am not on a solar farm yet?',
          answer: [
            "When you join Arcadia, we find solar farms to connect you to. The solar developer pays us for connecting people like you to the solar farm, so we don’t have to charge you anything to enroll. All you need to do is sync your utility account with Arcadia so that we can figure out what your portion of the project should be. Depending on your utility, you'll also pay your regular power bill through us so that we can automatically include your solar credits in one easy statement.",
            'Because we combine everything into one bill, we guarantee that your total bill will be lower — that is, the credits you earn from the utility will be larger than what you pay to the solar farm for your enrollment. You could save up to 5-10% of your bill.',
          ],
        },
        {
          question: 'How does community solar work?',
          answer: [
            'When you join a community solar farm, you’re subscribing to a portion of the total energy that farm produces. Your portion is based on how much energy you use in a typical month. The clean solar energy from the solar panels flows directly into the local power supply for homes and businesses to use. As your solar farm generates electricity, you earn credits on your monthly bill from the utility. Finally, Arcadia directs part of your total power bill to the solar farm, to keep it in business.',
            'Arcadia manages this whole process for you. Because we combine everything into one bill, we guarantee that your total bill will be lower — that is, the credits you earn from the utility will be larger than what you pay to the solar farm for your enrollment. You could save up to 5-10% of your bill.',
            'The best part? There’s no cost to join.',
          ],
        },
      ],
      acb: [
        {
          question: 'Why am I enrolled in automatic payments?',
          answer: [
            'This is how Arcadia can manage and ensure the payment of your electric utility bill to both your utility and the solar farm on time.',
          ],
        },
        {
          question:
            'Can I change the date of my automatic payment with Arcadia?',
          answer: [
            "As our billing system is automated, we're not able to set a specific billing date. This timeline ensures there is enough margin to process your payment and get it to your utility on time.",
            'However, you can delay your payment within the three day grace period after you receive your Arcadia statement, by clicking the “Delay Payment” button, above.',
          ],
        },
        {
          question: 'How does Arcadia manage my %{utility} bill?',
          answer: [
            '<ol><li>Each month, your utility will send your bill directly to us on or around the issue date.</li><li>We’ll then email you to let you know your Arcadia statement is available</li><li>Three days later, we’ll charge the payment method provided to us for the total reflected in your statement</li><li>Once your payment is done processing, we’ll make sure your utility bill is paid on time, every time.</li>',
          ],
        },
        {
          question: 'Do I need to pay my %{utility} bill?',
          answer: [
            'No, you do not. Each month, you will pay Arcadia directly, and Arcadia will manage your payment to your utility, so you do not have to pay them directly.',
          ],
        },
      ],
      dual: [
        {
          question: 'How does billing work if I pay my own utility bill?',
          answer: [
            'Every month you will receive two bills:',
            '<ul><li>One from your utility company with your regular utility charges, minus the value of your solar bill credits.</li><li>One from Arcadia for the contribution to the solar farm, which equals the solar credits you receive on your utility bill, minus your discount. Once we connect you to a local Community Solar project and that project starts producing energy, you will start seeing savings on your bill every month for as long as you are enrolled with us, for the determined length of the project. All Community Solar projects are remote, meaning there is no installation necessary on your own home. The energy produced is sent back to the local grid.</li><li>Each Arcadia member that is participating in the program will receive a percentage of the solar savings that are generated by their panel subscription, and the remaining savings generated will be used to maintain and continue the project.</li></ul>',
            "Together, these two bills-- your utility bill and your solar farm contribution -- add up to less than what you would have paid your utility company without community solar. You're guaranteed to save every month.",
          ],
        },
        {
          question: 'Why will I still receive a bill from my utility?',
          answer: [
            'Solar credits generally won’t offset 100% of the cost of the electricity you use in a month — you pay your utility the remainder.',
            'Additionally, your utility bill typically includes charges for transporting electricity from where it is generated to your residence. These charges will always be present, even if your solar credits offset 100% of your electricity usage.',
          ],
        },
        {
          question: 'How does bill payment work?',
          answer: [
            "You'll pay your utility company for your power bill as you normally do. For your contribution to the solar farm, we'll charge your payment method on file three days after you receive your monthly Arcadia statement. This is always an automatic payment, so no action is needed on your end. We'll route the funds to the solar developers to help maintain the solar farm.",
            '<ul><li>Payment Method: You can pay via Visa, MasterCard, American Express, Discover or a checking account.</li><li>Payment Date: Three days after you receive your Arcadia statement</li></ul>',
          ],
        },
        {
          question: 'Do I pay more with two bills?',
          answer: [
            'Nope — with the solar credits you earn by being a community solar member, these two bills will add up to less than what you would have paid without community solar.',
          ],
        },
      ],
    },
    printedStatement: {
      sender: {
        name: 'Arcadia',
        street: '<span class="notranslate">5600 S Quebec St.</span>',
        streetTwo: '<span class="notranslate">Suite 310D, 320D</span>',
        cityStateZip:
          '<span class="notranslate">Greenwood Village, CO 80111</span>',
      },
      title: 'Statement for %{email}',
      accountInfo: 'Account information:',
      overview: {
        paymentDate: 'Payment date',
        paymentMethod: 'Payment method',
        servicePeriod: 'Service period',
        productionPeriod: 'Production period',
        utilityAcccount: 'Utility account #',
      },
      lineItems: {
        product: 'Product',
        amount: 'Amount',
      },
    },
    banner: {
      refundedPartial: 'This statement has been refunded $%{dollarAmount}.',
      refundedFull: 'This statement has been refunded.',
      disputed: 'This statement has been disputed.',
    },
  },
  statements: {
    title: 'Billing history',
  },
  billingExplainer: {
    title: 'How does billing work?',
    arcadiaConsolidatedBilling: {
      steps: [
        'Your utility will send your bill directly to us on or around the issue date.',
        'When you join a solar farm, you get credits back as the farm generates electricity. We automatically subtract those credits from your power bill and email you one simple statement that shows your regular utility charges and your solar savings. You can also see your statement in your dashboard.',
        'Three days later, we’ll charge the payment method on file for your statement total. This is an automatic payment, so no action is needed on your end.',
      ],
    },
    dualBilling: {
      preStepText: 'Every month, you’ll receive two bills:',
      steps: [
        'One bill from your utility company with your regular utility charges, minus the value of your solar bill credits.',
        'One bill from Arcadia for your solar farm contribution: once the farm you’ve joined starts producing energy, you will receive a percentage of the solar savings that are generated, and the remaining savings generated will be used to maintain and continue the farm.',
      ],
      postStepParagraphs: [
        'Together, these two bills — your utility bill and your solar farm contribution — add up to less than what you would have paid your utility company without community solar. You’re guaranteed to save every month.',
        'You’ll pay your utility company for your power bill as you normally do. For your contribution to the solar farm, we’ll charge your payment method on file three days after you receive your Arcadia statement. This is always an automatic payment, so no action is needed on your end. We’ll route the funds to the solar developers to help maintain the solar farm.',
      ],
      lastParagraphText: 'Want more information?',
      lastParagraphLinkText: 'Read more about dual billing',
    },
    utilityConsolidatedBilling: {
      steps: [
        'You’ll get a monthly statement from %{utility} that includes your utility charges as well as your solar credits. You’ll pay your %{utility} bill just like you do now.',
        'Your utility will send a portion of your payment to your solar farm. Your contribution to the solar farm helps it stay up and running.',
      ],
      utilityPlaceholder: 'your utility',
      summary:
        'That’s it! You don’t have to worry about a thing, because it’s all done through your utility.',
      utilityLink: 'Go to %{utility}’s website',
    },
  },
  emptyState: {
    hero: {
      title: 'Billing',
      description:
        'Sit tight! Your first Arcadia bill will appear here once it’s ready.',
    },
    latestStatement: {
      title: 'Latest statement',
      description: 'Your most recent statement will appear here each month.',
    },
  },
};
