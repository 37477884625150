import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { useSessionContext } from 'contexts/session';

export const useUser = ({ skip = false } = {}) => {
  const { userId } = useSessionContext();
  const { data, error, loading, refetch } = useQuery<
    GetUserQuery,
    GetUserQueryVariables
  >(GET_USER_QUERY, {
    skip,
    variables: { userId },
  });

  return { data: data?.user, error, loading, refetch };
};

export const GET_USER_QUERY = gql`
  query GetUser($userId: Int!) {
    user(id: $userId) {
      id
      name
      firstName
      lastName
      email
      preferences {
        enabledGoogleSignIn
      }
      languageShortCode
      communicationPreferences
      zipCode {
        id
        zip
        city
      }
      lmiProofDocs {
        proofDocUrls
        proofDocName
        proofDocStatus
        proofDocUploadedAt
        uaLmiSourceTypeId
        utilityAccountId
        utilityName
        utilityAccountNumber
        utilityServiceAddress
      }
      originationSource {
        id
        funnel
        originationSourceType
      }
      userDocuSignEnvelopes {
        displayName
        contractType
        status
        completedFile
        envelopeUpdatedAt
        userId
        personalizedContractId
      }
    }
  }
`;

export const getUserMock = {
  user: {
    email: 'larry.lovebird@windmills.com',
    firstName: 'Larry',
    id: 10,
    lastName: 'Lovebird',
    name: 'Larry Lovebird',
    preferences: {
      enabledGoogleSignIn: true,
    },
    languageShortCode: 'en',
    communicationPreferences: ['email', 'transactional_sms'],
    lmiProofDocs: [
      {
        proofDocUrls: ['https://arcadia.com/lmi-proof-docs/1'],
        proofDocName: 'LMI Proof Doc 1',
        proofDocStatus: 'pending_review',
        proofDocUploadedAt: '01-01-2023',
        uaLmiSourceTypeId: 1,
        uaLmiSourceId: 1,
        utilityName: 'Arcadia Power',
        utilityAccountNumber: '1234567890',
        utilityServiceAddress: '123 Main St, Anytown, USA',
      },
    ],
    userDocuSignEnvelopes: [
      {
        displayName: 'Subscriber Verification of Low to Moderate Income Status',
        contractType: 'lmi',
        status: 'completed',
        completedFile: null,
        envelopeUpdatedAt: '2024-01-29',
        userId: '2566292',
        personalizedContractId: '172084',
      },
      {
        displayName: 'Subscriber Verification of Low to Moderate Income Status',
        contractType: 'lmi',
        status: 'sent',
        completedFile: null,
        envelopeUpdatedAt: '2024-01-10',
        userId: '2566292',
        personalizedContractId: '172084',
      },
      {
        displayName: 'Subscriber Verification of Low to Moderate Income Status',
        contractType: 'lmi',
        status: 'sent',
        completedFile: null,
        envelopeUpdatedAt: '2024-02-10',
        userId: '2566292',
        personalizedContractId: '172084',
      },
    ],
  },
};

export const useUserMock = {
  data: {
    ...getUserMock.user,
  },
  error: undefined,
  loading: false,
};
